/*!
 * Start Bootstrap - Simple Sidebar (https://startbootstrap.com/template/simple-sidebar)
 * Copyright 2013-2020 Start Bootstrap
 * Licensed under MIT (https://github.com/StartBootstrap/startbootstrap-simple-sidebar/blob/master/LICENSE)
 */

 #wrapper {
    -webkit-transition: padding .25s ease-out;
    -moz-transition: padding .25s ease-out;
    -o-transition: padding .25s ease-out;
    transition: padding .25s ease-out;
 }

 
/* Cambiar posicionamiento entre absoluto y estatico */
/* #sidebar-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 10000;
  padding-top: 0.5rem
}
.collapsed #sidebar-wrapper {
  position: static;
  height: 100%
}*/
/* Fin */

#sidebar-wrapper {
  /* padding-top: 0.5rem;
  padding-bottom: 0.5rem; */
  z-index: 10000;
  height: 100%;
}

@media (max-width: 768px) {
  #sidebar-wrapper {
    position: absolute;
  }

  .collapsed #sidebar-wrapper {
    position: static;
  }
}

@media (max-width: 576px) {
  .collapsed #sidebar-wrapper {
    display:none
  }
}

.sidebar-heading {
  overflow: hidden;
  font-size: 1.2rem;
  height: 140px;
}

.sidebar-heading-content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.collapsed #sidebar-wrapper:not(:hover) .sidebar-heading-content, .collapsed  #sidebar-wrapper:not(:hover) .sidebar-navitem-title {
  display: none
}

.collapsed  #sidebar-wrapper:not(:hover) .sidebar-navgroup-title {
  opacity: 0;
  width: 0;
}

.sidebar-navitem-icon svg {
  font-size: 1.6rem
}

.sidebar-navitem-title {
  margin-left: 0.5rem;
  vertical-align: middle;
}

.collapsed #sidebar-wrapper:not(:hover) .sidebar-menu {
  width: auto;
}
.sidebar-menu .list-group-item + .list-group-item.active {
  margin-top: 0;
  border-top-width: 0;
}

.sidebar-menu {
  position: sticky;
  top: 1rem;
  width: 15rem;
  margin-bottom: 0.5rem;
}

#page-content-wrapper {
  min-width: 100vw;
}