#wrapper {
  min-height: calc(100vh - 3.5rem);
}

::-webkit-calendar-picker-indicator {
  padding: revert;
  margin-left: -10px;
}

.fw-semibold {
  font-weight: 600 !important;
}
