.auth-wrapper {
  height: calc(100vh - 3.5rem);
  display: flex;
  flex-grow: 1;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}

.auth-inner {
  width: 450px;
  margin: auto;
  background: #ffffff;
  /* box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2); */
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  transition: all .3s;
}