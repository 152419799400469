.project-menu {
  min-width: 0;
}

.project-menu .dropdown{
  width: 100%
}
.project-menu .dropdown-toggle {
  display: flex;
  align-items: center;
}

.project-menu .dropdown .project-name {
  text-overflow: ellipsis;
  display: inline-block;
  overflow: hidden;
  vertical-align: bottom;
}